<p-dialog
  [header]="respuesta?.titulo"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
  [(visible)]="internalVisible"
>
<button 
          class="btn btn-xs"
          style="background-color: #92ce3c"
          (click)="editar_mostrar_v()"
        >
          Editar
        </button><br>
        <br>
  <form [formGroup]="UploadForm" (submit)="onSubmit($event)" [hidden]="editar_mostrar">
    <span
      >ID: <strong>{{ respuesta?.id_archivo }}</strong></span
    >
    <p *ngIf="rolUser == 'lector'">{{ respuesta?.contenido }}</p>
    <div *ngIf="rolUser != 'lector'">
      <div class="row">
        <div class="col-12">
          <textarea
            formControlName="descripcion"
            name="titulo"
            cols="30"
            class="form-control"
            rows="4"
            [value]="respuesta?.contenido"
          >
        respuesta?.contenido</textarea
          >
          
        </div>
        
      </div>
    </div>
    <div class="row text-left">
      <div class="col-3">
        <h6>peso:</h6>
        <p>{{ formatBytes(archivo?.archivo?.size) }}</p>
      </div>
      <div class="col-3" *ngIf="respuesta?.metadata.mimetype.includes('image')">
        <h6>Dimensiones:</h6>
        <p>
          {{ respuesta?.metadata?.info?.width }} *
          {{ respuesta?.metadata?.info?.height }}
        </p>
      </div>
      <div class="col-3">
        <h6>Extensión:</h6>
        <p>{{ respuesta?.metadata.extencion }}</p>
      </div>
      <div class="col-3">
        <h6>Etiquetas:</h6>
         <div *ngFor="let et of etiquetas[0]"  style="margin-right: 4px;margin-top: 4px;display: inline-table;">

           <p-tag   [value]="et" severity="success"></p-tag>
         </div>
          
        
      </div>
    </div>

    <br />

    <div class="row">
      <div class="col-6">
        <button
          pButton
          type="button"
          label="&nbsp;"
          class="p-button-danger"
          (click)="cerrar()"
        >
          Cerrar
        </button>
      </div>
      <div class="col-6" style="text-align: right" *ngIf="rolUser != 'lector'">
        <!-- <button pButton type="button" label="&nbsp;" class="p-button-danger" (click)="cerrar()">Cerrar</button> -->
        <!-- <a pButton class="p-button-success" label="&nbsp;" [href]="link" target="new">Descargar</a> -->
        <button
          type="submit"
          class="btn btn-lg"
          style="background-color: #92ce3c"
        >
          Descargar
        </button>
      </div>
    </div>
  </form>
  <div [hidden]="!editar_mostrar">
    <div class="col-12">
      <div class="container">
          <div class="row">

            <div *ngFor="let et of etiquetas[0]; let i = index" class="col-4">
                <div *ngIf="i === 0">
                    <input
                    type="text"
                    class="form-control"
                    placeholder="Añadir etiqueta"
                    (change)="AddEtiqueta($event)"
                  />
                </div>
                <div  *ngIf="et != respuesta?.id_archivo">
                  <input
                    type="text"
                    class="form-control"
                    (change)="editarEtiqueta($event, et)"
                    [value]="et"
                  />
                </div>
                <br>
              </div>
          </div>
        
        
      </div>
    </div>
  </div>
</p-dialog>
