import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'
]
})
export class LoginComponent{
  public internalVisible = false;
  loginForm: FormGroup =  this.fb.group({
    usuario   : ['',[Validators.required,Validators.email]],
    contrasena: ['',[Validators.required,Validators.minLength(4)]]
  })
  recoveryForm: FormGroup = this.fb.group({
    email : ['',[Validators.required,Validators.email]]
  })
  constructor(private fb: FormBuilder,private auth: AngularFireAuth,
    private messageService: MessageService,
    private userService: UserService,
    private router: Router,
    ){};
  campoesvalido(campo:string){
    return this.loginForm.controls[campo].errors && this.loginForm.controls[campo].touched;
  }
  async ingresar(){
    this.loginForm.markAllAsTouched();
    if(!this.loginForm.valid) return;
    try {
      await this.auth.signInWithEmailAndPassword(this.loginForm.controls.usuario.value,this.loginForm.controls.contrasena.value).then((data)=>[
        //this.userService.token(data.user.getIdToken().)
        //this.router.navigate(['dashboard/subir'])
        this.userService.createtoken(data.user.uid).then(async ()=>{
          await this.userService.getUser(data.user.uid).then(async (respuestaUser:any)=>{
            
            await localStorage.setItem('usuario_nombre',respuestaUser[0]?.nombre);
            await localStorage.setItem('uid',data.user.uid);
            await localStorage.setItem('usuario_rol',respuestaUser[0]?.rol);
            if(respuestaUser[0]?.rol == "lector"){
              this.router.navigate(['dashboard/buscar']);
            }else{
              this.router.navigate(['dashboard/subir']);
            }
            
          })
          
        }).catch((err)=>{
          console.log(err)
          this.messageService.add({severity:'error',summary:'Datos no válidos.',detail:"No se pudo crear la sesión."})
        })
      ])
    } catch (error) {
      this.messageService.add({severity:'error',summary:'Datos no válidos.',detail:"Usuario y/o contraseña incorrectos."})
      this.loginForm.controls.contrasena.setValue("");
    }
    
    
  }
  mostrarReuperar(){
    this.internalVisible = !this.internalVisible;
  }
  async recuperar(){
    let email = this.recoveryForm.controls.email.value;
    try{
      this.auth.sendPasswordResetEmail(email);
      this.messageService.add({severity:'success',summary:'Datos enviados al correo',detail:"Enlace para recuperar contraseña enviado al correo."})
      this.internalVisible = false;
    }
    catch(e){
      this.messageService.add({severity:'error',summary:'Datos no válidos.',detail:"Error al recuperar cuenta"})
    }
  }
}
