

    <body data-layout="horizontal">
        <p-toast position="bottom-center"></p-toast>
        <!-- Begin page -->
        <div id="wrapper">

            <!-- Navigation Bar-->
            
            <app-menu [nombre]="nameUser" [rol]="rolUser"></app-menu>
            <div class="content-page">
                <div class="content">
                    <div class="p-grid">
                        <div class="p-col"></div>
                        <div class="p-col" style="justify-content: center;align-items: center;">
                            <span class="p-fluid">
                                <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"   [(ngModel)]="texts" [suggestions]="results" (completeMethod)="search($event)" [multiple]="true" ></p-autoComplete>
                            </span>
                            
                            <ng-template let-value pTemplate="selectedItem">
                                <span style="font-size:18px;width: 100%;">{{value}}</span>
                            </ng-template>
                            <!-- <form (ngSubmit)="buscar()" [formGroup]="buscarForm">
                                <div >
                                    <span class="p-input-icon-left" style="width: 100%;" >
                                        <i class="pi pi-search"></i>
                                        <input  formControlName="buscarInput" style="width: 100%;" type="text" pInputText class="p-inputtext-lg"  placeholder="Buscar ...">         
                                    </span>
                                </div>
                                
                            </form> -->
                            <button class="btn btn-success" style="background-color: #92ce3c;" (click)="buscar()">Buscar</button>
                        </div>
                        <div class="p-col"></div>
                    </div>
                    <br>
                    <app-gallery [miniaturas]="miniaturas"></app-gallery>
                    
                    </div>
            </div>
            </div>

    </body>