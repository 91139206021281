import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OndriveService } from 'src/app/services/ondrive.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  @Input() nombre;
  @Input() rol;
  internalVisible = false;
  internalVisible2 = false;
  internalVisible3 = false;

  public etipopulares:any[];
  registerUser: FormGroup = this.fb.group({
    nombre: ['',Validators.required],
    email: ['',Validators.required,Validators.email],
    password: ['',Validators.required,Validators.minLength(4)],
    rol: ['',Validators.required]
  })
  public usuariosSubidas:any[];
  constructor(
    private fb: FormBuilder,
    private auth: AngularFireAuth,
    private userProvider: UserService,
    private http: HttpClient,
    private ondriveService: OndriveService
  ) { 
    
  }

  ngOnInit(): void {
    this.getListado();
    this.getEtiquetasPopulares();
  }
  async getEtiquetasPopulares(){
    var formData: any = new FormData();
    formData.append('x-token',this.userProvider.token)
          
          await this.http.post(`${this.ondriveService.urlOndrive}server/getEtiquetasPopulares`,formData).toPromise().then((data:any)=>{
              this.etipopulares = data;
              
              
            
          })
  }
  async getListado() {
    try {
      var formData: any = new FormData();
      formData.append('x-token', this.userProvider.token);
      await this.http
        .post(
          `${this.ondriveService.urlOndrive}server/getUsuarios`,
          formData
        )
        .toPromise()
        .then((data:any) => {
          this.usuariosSubidas = data;
          
        })
        .catch((e) => {});
    } catch (error) {}
  }
  registrar(){
    if(!this.registerUser.invalid){
      let email = this.registerUser.controls.email.value;
      let password = this.registerUser.controls.password.value;
      let nombre = this.registerUser.controls.nombre.value;
      let rol = this.registerUser.controls.rol.value;
      this.auth.createUserWithEmailAndPassword(email,password).then((data)=>{
        this.userProvider.addUSer(data.user.uid,nombre,rol).then((d)=>{
          this.internalVisible = false;
          this.registerUser.controls.email.reset();
          this.registerUser.controls.password.reset();
          this.registerUser.controls.nombre.reset();
          this.registerUser.controls.rol.reset();
        })
      });
    }
  }
  eliminarUsuario(){
    
  }
  mostrarRegistrar(){
    this.internalVisible = !this.internalVisible;
  }
  mostrarVer(){
    this.internalVisible2 = !this.internalVisible2;
  }
  mostrarEti(){
    this.internalVisible3 = !this.internalVisible3;
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.usuariosSubidas); // Sale Data
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "sales");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }
}
