<header id="topnav" style="top:0;">
    <!-- Topbar Start -->
    <div class="navbar-custom" style="background-color: #92ce3c;">
        <div class="container-fluid">
            <ul class="list-unstyled topnav-menu float-right mb-0">
                
                <li class="dropdown notification-list">
                    <!-- Mobile menu toggle-->
                    <a class="navbar-toggle nav-link">
                        <div class="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </a>
                    <!-- End mobile menu toggle-->
                </li>

               
        

                <li class="dropdown notification-list">
                    <a class="nav-link dropdown-toggle nav-user mr-0" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                        
                        <span class="pro-user-name d-none d-xl-inline-block ml-2">
                                                {{nombre}}  <i class="mdi mdi-chevron-down"></i> 
                                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
                       
                        <a href="/login" class="dropdown-item notify-item">
                            <i class="mdi mdi-logout-variant"></i>
                            <span>Cerrar Sesión</span>
                        </a>

                    </div>
                </li>


            </ul>

            <!-- LOGO -->
            <div class="logo-box">
                <a href="dashboard/subir" class="logo text-center" *ngIf="rol !='lector'">
                    <span class="logo-lg">
                            <img src="./assets/images/logo-light.png" alt="" height="26">
                            <!-- <span class="logo-lg-text-light">Simple</span> -->
                    </span>
                    <span class="logo-sm">
                            <!-- <span class="logo-sm-text-dark">S</span> -->
                    <img src="./assets/images/logo-sm.png" alt="" height="22">
                    </span>
                </a>
                <a href="dashboard/buscar" class="logo text-center" *ngIf="rol =='lector'">
                    <span class="logo-lg">
                            <img src="./assets/images/logo-light.png" alt="" height="26">
                            <!-- <span class="logo-lg-text-light">Simple</span> -->
                    </span>
                    <span class="logo-sm">
                            <!-- <span class="logo-sm-text-dark">S</span> -->
                    <img src="./assets/images/logo-sm.png" alt="" height="22">
                    </span>
                </a>
            </div>

            <div id="navigation">
                <!-- Navigation Menu-->
                <ul class="navigation-menu">
                    
                    <li class="has-submenu">
                        <a href="/subir" *ngIf="rol !='lector'">
                            <i   class="ti-home"></i>Subir
                        </a>
                    </li>
                    <li class="has-submenu">
                        <a href="/buscar">
                            <i class="ti-home"></i>Buscar
                        </a>
                    </li>
                    <li class="has-submenu" *ngIf="rol=='admin'" style="cursor: pointer;">
                        <a (click)="mostrarRegistrar()">
                            <i class="ti-home"></i>Añadir usuario
                        </a>
                    </li>
                    <li class="has-submenu" *ngIf="rol=='admin'" style="cursor: pointer;">
                        <a (click)="mostrarVer()">
                            <i class="ti-home"></i>Ver usuarios
                        </a>
                    </li>
                    
                    <li class="has-submenu" *ngIf="rol=='admin'" style="cursor: pointer;">
                        <a (click)="mostrarEti()">
                            <i class="ti-home"></i>Ver Etiquetas
                        </a>
                    </li>
                   
                </ul>
                <!-- End navigation menu -->

                <div class="clearfix"></div>
            </div>
            <!-- end #navigation -->

            <div class="clearfix"></div>
        </div>
    </div>
    <!-- end Topbar -->
</header>
<p-dialog header="Registrar Usuario" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '30vw'}" [(visible)]="internalVisible">
    <form [formGroup]="registerUser" autocomplete="off" (ngSubmit)="registrar()">
        <div class="form-group">
            <label for="name">Nombre</label>
            <input class="form-control"
            formControlName="nombre"
            type="text" id="name" placeholder="Nombre de usuario">
            
        </div>
        <div class="form-group">
            <label for="name">Email</label>
            <input class="form-control"
            formControlName="email"
            type="email" id="name" placeholder="Email">
            
        </div>
        <div class="form-group">
            <label for="password">Contraseña</label>
            <input class="form-control"
            formControlName="password"
            type="password" id="password" placeholder="Contraseña">
            
        </div>
        <div class="form-group">
            <label for="rol">Rol</label>
            <select formControlName="rol" class="form-control" name="rol" id="rol">
                <option value="admin">Administrador</option>
                <option value="editor">Editor</option>
                <option value="lector">lector</option>


            </select>
            
        </div>
        <div class="mb-3 text-center">
            <button class="btn btn-primary btn-block" type="submit" [disabled]="registerUser.invalid" style="background-color: #92ce3c;"> Registrar</button>
        </div>
    </form>

</p-dialog>
<p-dialog header="Usuarios" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '30vw'}" [(visible)]="internalVisible2">
  <p-table [value]="usuariosSubidas">
    <ng-template pTemplate="caption">
        <div>
            <button type="button" (click)="exportExcel()" class="p-button-success p-mr-2">Descargar</button>
        </div>
    </ng-template>
        <ng-template pTemplate="header">
            
            <tr>
                <th>Nombre</th>
                <th>Subidas</th>
                
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
            <tr >
                <td>{{car.nombre}}</td>
                <td>{{car.subidas}}</td>
                
            </tr>
        </ng-template>
    </p-table>
</p-dialog>
<p-dialog header="Etiquetas Generales" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{'background-image':'url(https://eastus1-mediap.svc.ms/transform/thumbnail?provider=spo&inputFormat=JPG&cs=NjY1ODk4ODAtOTU4OC00ZjBkLWE1NzYtYTIwODhkMmVlZjY4fFNQTw&docid=https%3A%2F%2Ffedegan%2Esharepoint%2Ecom%2F%5Fapi%2Fv2%2E0%2Fdrives%2Fb%21CtfhWuft502%2DqUkwt5LofmNeoD3xONJNtoWKbtTaaRddcLOGnB0OTq5khZSC%2DKpH%2Fitems%2F01CSBTHJ2YGE55LVWO4REKJDMEDWDHHQPR%3Ftempauth%3DeyJ0eXAiOiJKV1QiLCJhbGciOiJub25lIn0%2EeyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvZmVkZWdhbi5zaGFyZXBvaW50LmNvbUA4MmEyODAwNi1lNjUzLTRkZGYtOWQ1ZS03NzZkYWEzODE1NmEiLCJpc3MiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAiLCJuYmYiOiIxNjI1NjkxNjAwIiwiZXhwIjoiMTYyNTcxMzIwMCIsImVuZHBvaW50dXJsIjoiTVYrVnNMdmk4RThQR2RkZ1czaVpsUW1WeDE5OUp2eGVYUzAyVGRqVGVnYz0iLCJlbmRwb2ludHVybExlbmd0aCI6IjE1NSIsImlzbG9vcGJhY2siOiJUcnVlIiwidmVyIjoiaGFzaGVkcHJvb2Z0b2tlbiIsInNpdGVpZCI6Ik5XRmxNV1EzTUdFdFpXUmxOeTAwWkdVM0xXSmxZVGt0TkRrek1HSTNPVEpsT0RkbCIsImFwcF9kaXNwbGF5bmFtZSI6IkFwcCBvbmRyaXZlIiwibmFtZWlkIjoiNjY1ODk4ODAtOTU4OC00ZjBkLWE1NzYtYTIwODhkMmVlZjY4QDgyYTI4MDA2LWU2NTMtNGRkZi05ZDVlLTc3NmRhYTM4MTU2YSIsInJvbGVzIjoiZ3JvdXAucmVhZCBncm91cC53cml0ZSBhbGxmaWxlcy53cml0ZSBhbGxmaWxlcy5yZWFkIGFsbHByb2ZpbGVzLndyaXRlIGFsbHByb2ZpbGVzLnJlYWQiLCJ0dCI6IjEiLCJ1c2VQZXJzaXN0ZW50Q29va2llIjpudWxsfQ%2EdGY4YlJuZ3lTNEV5d1RsOTZqc1RqdExhSUsxREpmL0UwVDJOakVQQ2hLRT0%26version%3DPublished&width=800&height=800&cb=63761299131)'}" contentStyle=": ')" [style]="{width: '30vw'}" [(visible)]="internalVisible3">
    
    <p-table [value]="etipopulares">
          <ng-template pTemplate="header">
              <tr>
                  <th>Nombre</th>
                  <th>Subidas</th>
                  
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-car>
              <tr >
                  <td>{{car.nombre}}</td>
                  <td>{{car.total}}</td>
                  
              </tr>
          </ng-template>
      </p-table>
  </p-dialog>