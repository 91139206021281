<p-toast position="top-right"></p-toast>
<div class="account-pages  pt-5" style="height: 100vh;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center">
                            <a href="/">
                                <span><img src="assets/images/logo-dark.png" alt="" style="width: 50%;"></span>
                            </a>
                            <h3>Gestión de archivos multimedia</h3>

                        </div>
                        <form action="mt-3" class="p-2" [formGroup]="loginForm" autocomplete="off" (ngSubmit)="ingresar()">
                            <div class="form-group">
                                <label for="emailaddress">Usuario</label>
                                <input class="form-control"
                                formControlName="usuario"
                                type="email" id="emailaddress" placeholder="Ingresa tu correo">
                                <small id="passwordHelp" *ngIf="campoesvalido('usuario')" class="text-danger">
                                    Ingresar un email válido.
                                  </small>  
                            </div>
                            
                            <div class="form-group">
                                <a  style="cursor: pointer;" (click)="mostrarReuperar()" class="text-muted float-right">¿Recuperar contraseña?</a>
                                <label for="password">Contraseña</label>
                                <input class="form-control"
                                formControlName="contrasena"
                                type="password"  id="password" placeholder="Ingresa tu contraseña">
                                <small id="passwordHelp" class="text-danger" *ngIf="campoesvalido('contrasena')">
                                    La contraseña es obligatoria.
                                  </small>  
                            </div>

                            <div class="form-group mb-4 pb-3">
                                <!-- <div class="custom-control custom-checkbox checkbox-primary">
                                    <input type="checkbox" class="custom-control-input" id="checkbox-signin">
                                    <label class="custom-control-label" for="checkbox-signin">Recuerdame</label>
                                </div> -->
                            </div>
                            <div class="mb-3 text-center">
                                <button class="btn btn-primary btn-block" type="submit" [disabled]="loginForm.invalid" style="background-color: #92ce3c;"> Ingresar</button>
                            </div>
                        </form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
                <div class="row mt-4">
                    
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<p-dialog header="Recuperar cuenta" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '30vw'}" [(visible)]="internalVisible">
    <form [formGroup]="recoveryForm" autocomplete="off" (ngSubmit)="recuperar()">
        <div class="form-group">
            <label for="emailaddress">Email</label>
            <input class="form-control"
            formControlName="email"
            type="email" id="emailaddress" placeholder="Ingresa tu correo">
            
        </div>
        <div class="mb-3 text-center">
            <button class="btn btn-primary btn-block" type="submit" [disabled]="recoveryForm.invalid" style="background-color: #92ce3c;"> Recuperar</button>
        </div>
    </form>

</p-dialog>