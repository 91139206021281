
import { Injectable } from '@angular/core';
import { rejects } from 'assert';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor() {}
  private token_user = "";
  get token () :string{
    let jwt = localStorage.getItem('jwt');
    return jwt;
  }
  createtoken(uid){
    return new Promise((resolve,reject)=>{
      var myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJ0dW1hZHJlIiwiaWF0IjoxNjE1NjQ0MzgyfQ.ZV7JMB0ZKYxbv36edpy4Qa_E_iLTJA9NeJseEsCYP0o");

    var formdata = new FormData();
    formdata.append("uid", uid);
    fetch(environment.urlback+"users/login/", {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    })
      .then((response) => response.json()).then(body=>{
        localStorage.setItem('jwt',body.jwt);
        resolve(true)
      })
      .catch(error => reject(error));
    })
  }
  validartoken(){
    return new Promise((resolve,reject)=>{
    try {
      let tk = localStorage.getItem('jwt');
      var myHeaders = new Headers();
        
      
  
      var formdata = new FormData();
      formdata.append("x-token", tk);
      fetch(environment.urlback+"users/login/validate", {
        method: 'POST',
        headers: myHeaders,
        body: formdata
      })
        .then((response) => response.json()).then((body:any)=>{
          
          
          if(body.error){ reject(false)}
          resolve(true)
        })
        .catch((error) => {reject(false);console.log('error type:')});
    } catch (error) {
      reject(false)
    }
   
    })
  }
  
  addUSer(id,nombre,rol){
    return new Promise(async (resolve,reject)=>{
      try {
        let tk = await localStorage.getItem('jwt');
        
        var myHeaders = new Headers();
          
        
    
        var formdata = new FormData();
        formdata.append("x-token", tk);
        formdata.append("id", id);
        formdata.append("nombre", nombre);
        formdata.append("rol", rol);
        fetch(environment.urlback+"server/user/add", {
          method: 'POST',
          headers: myHeaders,
          body: formdata
        }).then((response) => response.json()).then((body:any)=>{
            
            console.log(body)
            if(body.error){ reject(false)}
            
            resolve(body)
          })
          .catch((error) => {reject(false);console.log(error)});
      } catch (error) {
        reject(error)
      }
     
      })
   }
  getUser(UID:string){
    return new Promise(async (resolve,reject)=>{
      try {
        let tk = await localStorage.getItem('jwt');
        
        var myHeaders = new Headers();
          
        
    
        var formdata = new FormData();
        formdata.append("x-token", tk);
        formdata.append("q", UID);
        fetch(environment.urlback+"server/user", {
          method: 'POST',
          headers: myHeaders,
          body: formdata
        }).then((response) => response.json()).then((body:any)=>{
            
            console.log(body)
            if(body.error){ reject(false)}
            
            resolve(body)
          })
          .catch((error) => {reject(false);console.log(error)});
      } catch (error) {
        reject(error)
      }
     
      })
   }
}
