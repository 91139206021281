

    <body data-layout="horizontal">
        <p-toast position="bottom-center"></p-toast>
        <!-- Begin page -->
        <div id="wrapper">

            <!-- Navigation Bar-->
            <app-menu [nombre]="nameUser" [rol]="rolUser"></app-menu>
            <!-- End Navigation Bar-->

            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->
            
            <div class="content-page">
                <div class="content">

                    <!-- Start container-fluid -->
                    <div class="container-fluid">
                        <div class="row text-center ">
                            <div *ngFor="let tag of etipopulares;let i = index" class="col-2 d-xs-none d-sm-none d-md-block"  style="margin-right: 1em;">
                                <!-- <p-tag  [value]="': '+tag.total" severity="success">{{tag.nombre}}</p-tag> -->
                                {{tag.nombre}}
                                <div class="progress-circle-container">
                                    <div  [class]="'progress-circle progress-'+ getInteger((tag.total * 100) / total_eti.total)">
                                        <span> {{ tag.total }}</span>

                                    
                                    </div>  </div>
                            </div>
                        </div>
                        
                        <form class="form mt-4 mt-lg-0" [formGroup]="UploadForm" (submit)="onSubmit($event)" autocomplete="off">
                       
                        <div class="progress" *ngIf="subiendo">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                          </div><br>
                        <div class="row">
                            
                            <div class="col-7">
                                <h4 class="">{{UploadForm.controls.titulo?.value}}</h4>
                                <p>{{UploadForm.controls.descripcion?.value}}</p>
                                <div style="width: 100%;display: flex;flex-flow: row wrap;justify-content: flex-start;align-items: center;">
                                    <div *ngFor="let tag of tagsarray;let i = index"  style="margin-right: 1em;">
                                        <p-tag  [value]="tag" severity="success" icon="pi pi-times" (click)="deleteTag(i)"></p-tag>
                                        
                                    </div>
                                </div>
                                <br>
                                <div>
                                    <ngx-dropzone  (change)="onSelect($event)">
                                        <ngx-dropzone-label>Arrastra o selecciona.</ngx-dropzone-label>
                                        <div *ngFor="let f of files">
                                            <div *ngIf="f.type.includes('image')">
                                                <ngx-dropzone-image-preview [removable]="true" (removed)="onRemove(f)" ngProjectAs="ngx-dropzone-preview" [file]="f">
                                                    
                                                </ngx-dropzone-image-preview>
                                            </div>
                                            <div *ngIf="f.type.includes('video')">
                                                <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" [file]="f">
                                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                                  </ngx-dropzone-video-preview>

                                            </div>
                                            <div *ngIf="!f.type.includes('video') && !f.type.includes('image')">
                                                 <ngx-dropzone-preview  [removable]="true" (removed)="onRemove(f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview>

                                            </div>
                                        </div>
                                        <!-- <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-preview> -->
                                    </ngx-dropzone>
                                    
                                </div><br>
                                
                                <button type="submit" [disabled]="subiendo" class="btn btn-lg " style="background-color: #92ce3c;">Subir</button>

                            </div>
                            <div class="col-3">
                                    
                                    <div class="form-group">
                                       
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="fas fa-heading"></i></span>
                                            </div>
                                            
                                            <input type="text" formControlName="titulo" name="titulo"  class="form-control" placeholder="Título">
                                        </div>
                                        <br>
                                        <div class="input-group">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" (click)="addTag()"><i class="fas fa-plus"></i></span>
                                                </div>
                                                
                                                <input type="text"  formControlName="etiqueta" name="etiquetas"  class="form-control" placeholder="Add etiqueta">
                                            </div>
                                        </div><br>
                                        <textarea class="form-control" formControlName="descripcion"  rows="5" id="example-textarea" placeholder="Descripción"></textarea>
                                        
                                    </div>
                                    <!-- form-group -->

                              
                                
                            </div>
                            
                        </div>
                        <!-- end -->
                        </form><br/>
                        <h3>ÚLTIMAS SUBIDAS</h3>
                        <app-gallery [miniaturas]="miniaturas"></app-gallery>
                        
                        
                    </div>
                    
                    <!-- end container-fluid -->

                    

                    <!-- Footer Start -->
                    <footer class="footer">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                  
                                </div>
                            </div>
                        </div>
                    </footer>
                    <!-- end Footer -->

                </div>
                <!-- end content -->

            </div>
            <!-- END content-page -->

        </div>
        <!-- END wrapper -->

        
        <!-- Right Sidebar -->
        <div class="right-bar">
            <div class="rightbar-title">
                <a href="javascript:void(0);" class="right-bar-toggle float-right">
                    <i class="mdi mdi-close"></i>
                </a>
                <h5 class="font-16 m-0 text-white">Theme Customizer</h5>
            </div>
            <div class="slimscroll-menu">
        
                <div class="p-4">
                    <div class="alert alert-warning" role="alert">
                        <strong>Customize </strong> the overall color scheme, layout, etc.
                    </div>
                    <div class="mb-2">
                        <img src="./assets/images/layouts/light.png" class="img-fluid img-thumbnail" alt="">
                    </div>
                    <div class="custom-control custom-switch mb-3">
                        <input type="checkbox" class="custom-control-input theme-choice" id="light-mode-switch" checked />
                        <label class="custom-control-label" for="light-mode-switch">Light Mode</label>
                    </div>
            
                    <div class="mb-2">
                        <img src="./assets/images/layouts/dark.png" class="img-fluid img-thumbnail" alt="">
                    </div>
                    <div class="custom-control custom-switch mb-3">
                        <input type="checkbox" class="custom-control-input theme-choice" id="dark-mode-switch" data-bsStyle="./assets/css/bootstrap-dark.min.css" 
                            data-appStyle="./assets/css/app-dark.min.css" />
                        <label class="custom-control-label" for="dark-mode-switch">Dark Mode</label>
                    </div>
            
                    <div class="mb-2">
                        <img src="./assets/images/layouts/rtl.png" class="img-fluid img-thumbnail" alt="">
                    </div>
                    <div class="custom-control custom-switch mb-5">
                        <input type="checkbox" class="custom-control-input theme-choice" id="rtl-mode-switch" data-appStyle="./assets/css/app-rtl.min.css" />
                        <label class="custom-control-label" for="rtl-mode-switch">RTL Mode</label>
                    </div>

                    <a href="https://1.envato.market/EK71X" class="btn btn-danger btn-block mt-3" target="_blank"><i class="mdi mdi-download mr-1"></i> Download Now</a>
                </div>
            </div> <!-- end slimscroll-menu-->
        </div>
        <!-- /Right-bar -->

     

      

     