import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './shared/menu/menu.component';
import { UploadComponent } from './upload/upload.component';
import { DashboardComponent } from './dashboard.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http'
import {ToastModule} from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagModule } from 'primeng/tag';
import { DashboardRoutingModule } from './dashboard.routes.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { GalleryComponent } from '../widgets/gallery/gallery.component';
import { DialogModule } from 'primeng/dialog';
import {TableModule} from 'primeng/table';
import { RoundPipe } from './pipes/rounded';
@NgModule({
  declarations: [
    MenuComponent,
    UploadComponent,
    DashboardComponent,
    RoundPipe
  ],
  exports:[ 
    MenuComponent
  ],
  imports: [
    ToastModule,
    CommonModule,
    NgxDropzoneModule,
    FormsModule,
    HttpClientModule,
    TagModule,
    ReactiveFormsModule,
    WidgetsModule,
    DialogModule,
    TableModule
    
  ],
  providers: [
    MessageService
  ]
})
export class DashboardModule { }
