import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./auth/dashboard/login/login.component";
import { LoginGuard } from "./auth/login.guard";

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'login',
        loadChildren: ()=> import('./auth/dashboard/login/login.module').then((m)=>m.LoginModule)
    },
    {
        path: 'dashboard',
        loadChildren: ()=> import('./auth/auth.module').then(m => m.AuthModule),
        // canActivate: [
        //     LoginGuard
        // ],
        // canLoad: [
        //     LoginGuard
        // ]
    }
]
@NgModule({
    imports:[
        RouterModule.forRoot(routes)
    
    ],
    exports:[
        RouterModule
    ]
})
export class AppRoutingModule{

}
