import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArchivosService } from 'src/app/services/archivos.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.css']
})
export class BuscarComponent implements OnInit {
  texts: string[] = [];
  results: string[] = [];
  nameUser:string;
  rolUser:string;
  constructor(private fb:FormBuilder,private user:UserService,private archivos: ArchivosService) { 
    this.setUserVar();
  }
  miniaturas:any[] = [];
  visible = false;
  
  buscarForm: FormGroup =  this.fb.group({
    buscarInput   : ['']
  })
  ngOnInit(){
  }
  async setUserVar() {
    this.nameUser = await localStorage.getItem('usuario_nombre');
    this.rolUser = await localStorage.getItem('usuario_rol');
  }
  buscar(){
    var busqueda:string = this.texts.join();
    if(busqueda.length < 0){
      
      this.miniaturas = [];

      return;
    }
    if(busqueda){
      this.miniaturas = [];
      this.archivos.buscar(busqueda).then((data:any[])=>{
        if(data.length <=0){this.miniaturas = []}
        let cargados:any[] = [];
        data.forEach((d)=>{
          this.archivos.miniaturas(d.respuesta.id).then((da:any)=>{
            if(da.hasOwnProperty('id')){
              if(cargados.includes(da.id)){

              }else{
                cargados.push(da.id);
                let format = {
                  archivo: da,
                  respuesta: d
                }
                console.log(da.id);
                
                this.miniaturas.push(format);
              }
            }
            
            
            
          })
        })
       
      }).catch((e)=>{

      })
      console.log(this.miniaturas)
    }
  }

  sumar_descarga(){
    this.visible = true;
  
  }
  isVisible(data){
    console.log(data);
    this.visible = data;
  }
  limpiar(originalArray:any[], prop:string){
    var newArray = [];
     var lookupObject  = {};

     for(var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
     }

     for(i in lookupObject) {
         newArray.push(lookupObject[i]);
     }
      return newArray;
  }
  search(event) {
    this.archivos.etiquetas(event.query).then((data:string[])=>{
      this.results = data;
    })
    // this.mylookupservice.getResults(event.query).then(data => {
    //     this.results = data;
    // });
}
}
