import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { UserService } from 'src/app/services/user.service';
import { LoginRoutingModule } from './loginRouting.module';
import { DialogModule } from 'primeng/dialog';



@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ToastModule,
    LoginRoutingModule,
    DialogModule
  ],
  providers: [
    UserService
  ]
})
export class LoginModule { }
