import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard/dashboard.routes.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { UploadComponent } from './dashboard/upload/upload.component';
import { LoginModule } from './dashboard/login/login.module';
import {InputTextModule} from 'primeng/inputtext';
import { BuscarComponent } from './dashboard/buscar/buscar.component';
import { ToastModule } from 'primeng/toast';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ButtonModule} from 'primeng/button';

import { ModalComponent } from './widgets/modal/modal.component';
import { GalleryComponent } from './widgets/gallery/gallery.component';
import { WidgetsModule } from './widgets/widgets.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
@NgModule({
  declarations: [
    BuscarComponent
  ],
  imports: [
    ToastModule,
    CommonModule,
    DashboardModule,
    LoginModule,
    DashboardRoutingModule,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    WidgetsModule,
    AutoCompleteModule
    
  ],
  exports:[

  ]
})
export class AuthModule { }
