<section id="gallery">
  <div class="card" *ngFor="let archivo of miniaturas"  (click)="sumar_descarga(archivo)">
    <img class="content-image" [src]="archivo?.archivo?.thumbnails[0]?.large.url">
    <div class="card-body">
      <h5 class="card-title">{{archivo.respuesta.titulo}}</h5>
      <p  class="card-text" style="line-height: 0;">{{archivo.respuesta.contenido | slice:0:20}} ...</p>
      <div *ngIf="archivo.respuesta.metadata.mimetype.includes('image')">
        <span style="text-align: left;color: black">Dim: {{archivo.respuesta.metadata.info?.width}} * {{archivo?.respuesta?.metadata?.info?.height}} </span>
        <span style="text-align: left;color: black">Extención: {{archivo?.respuesta?.metadata?.extencion}} </span>
        
      </div>
    </div>
  </div>
</section>
<app-modal   [visible]="eventsSubject.asObservable()" (change)="cambio($event)" [archivo]="tmpFile[0]" (isVisible)="isVisible($event)"  [respuesta]="tmpFile[0]?.respuesta" [etiquetas]="tmpFile[0]?.respuesta.etiquetas" [link]="tmpFile[0]?.archivo['@microsoft.graph.downloadUrl']" [etiquetasSub]="eventsEti.asObservable()" [etiquetas]="tmpEtiqueta" ></app-modal>