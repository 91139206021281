import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ArchivosService } from 'src/app/services/archivos.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  @Input() miniaturas:any[] =  [];
  public visible = false;
  public tmpFile:any[] = [];
  public tmpEtiqueta:any[] = [];
  public tmpFileActive = false;
  eventsSubject: Subject<boolean> = new Subject<boolean>();
  eventsEti: Subject<any[]> = new Subject<any[]>();
  emitEventToChild(val) {
    this.eventsSubject.next(val);
    
  }
  emitChildEti(val){
    this.eventsEti.next(val);
  }

  constructor(private archivosService: ArchivosService) { 
    
  }

  ngOnInit(): void {
    this.emitEventToChild(false);
  }
  async sumar_descarga(archivo){
   
    this.tmpFile = [];
    this.tmpEtiqueta = [];
    this.visible = true;
    this.tmpFile.push(archivo);
    await this.getEtiquetas();
    
    this.tmpFileActive = true;
    this.emitEventToChild(true);
  }
  isVisible(data){
    this.visible = data;
    this.tmpFileActive = data;
    this.emitEventToChild(false);
    this.emitChildEti(this.tmpEtiqueta)
  }
  cambio(data){
    this.visible = false;
    this.visible = true;
    this.emitChildEti(data)
  }
   async getEtiquetas(){
    await  this.archivosService.etiquetasId(this.tmpFile[0]?.respuesta?.id).then((data)=>{
      
      this.tmpEtiqueta.push(data)
      return data;
    })
  }
  
  
}
