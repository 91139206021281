import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Observer } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { ArchivosService } from 'src/app/services/archivos.service';
import { OndriveService } from 'src/app/services/ondrive.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
})
export class ModalComponent implements OnInit {
  private eventsSubscription: Subscription;
  private etiSubscription: Subscription;

  @Input() id: String = '';
  @Input() respuesta: any;
  @Input() contenido: String = '';
    public etiquetas_data: any[];
  @Input() etiquetas:any[];
  @Input() etiquetasSub : Observable<any[]>;
  @Input() link: String = '';
  @Input() archivo;
  @Input() visible: Observable<boolean>;
  public internalVisible: boolean;
  public  editar_mostrar: boolean = false;
  public stateChange = false;
  @Output() isVisible = new EventEmitter<boolean>();
  @Output() change = new EventEmitter<any[]>();
  public rolUser;
  UploadForm: FormGroup = this.fb.group({
    descripcion: ['', [Validators.required]],
  });
  public obstrin;
  constructor(
    private fb: FormBuilder,
    private user: UserService,
    private ondriveService: OndriveService,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.getRol();
   
    
  }

  ngOnInit(): void {
    this.eventsSubscription = this.visible.subscribe((d) => {
      this.internalVisible = d;
      this.editar_mostrar = false;
    });
    this.etiSubscription = this.etiquetasSub.subscribe((d)=>{
      if(this.stateChange){

        this.etiquetas = d;
        this.stateChange = false;
      }
      
    })
     
    
    
  }
  ngDestroy(){
  }

  addSingle(texto: string, tipo: string, detalle: string) {
    this.messageService.add({
      severity: tipo,
      summary: texto,
      detail: detalle,
    });
  }
  editar_mostrar_v(){
    this.editar_mostrar = !this.editar_mostrar;
  }
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  cerrar() {
    
    this.isVisible.emit(false);
  }
  async getRol() {
    this.rolUser = await localStorage.getItem('usuario_rol');
  }
  formatBytes(bytes, decimals = 3) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  async onSubmit(event) {
    
    event.preventDefault();
    let descripcion = this.UploadForm.controls['descripcion'].value;
    if (descripcion === this.respuesta?.contenido) {
      this.addSingle(
        'Actualizar descripción',
        'error',
        'Descripción es obligatoria'
      );
      return;
    }
    if (descripcion == '') {
      this.addSingle(
        'Actualizar descripción',
        'error',
        'Descripción es obligatoria'
      );
      return;
    } else {
      var formData: any = new FormData();
      formData.append('x-token', this.user.token);
      formData.append('id', this.respuesta?.id_archivo);
      formData.append('contenido', descripcion);
      await this.http
        .post(`${this.ondriveService.urlOndrive}server/update`, formData)
        .toPromise()
        .then((data) => {
          this.onNavigate();
          this.cerrar();
        })
        .catch((e) => {});
    }
  }
  async editarEtiqueta(evento, anterior) {
    
    let buscar = this.etiquetas[0].indexOf(anterior);
    this.etiquetas[0][buscar]= evento.target.value;    
    let et:any[] = this.etiquetas;
    try {
      var formData: any = new FormData();
      formData.append('x-token', this.user.token);
      formData.append('id', this.respuesta?.id);
      formData.append('anterior', anterior);
      formData.append('nueva', evento.target.value);
      formData.append('junta', this.etiquetas.toString());

      await this.http
        .post(
          `${this.ondriveService.urlOndrive}server/updateEtiqueta`,
          formData
        )
        .toPromise()
        .then((data) => {
          this.stateChange = true;
          this.change.emit(et);
          this.addSingle('Actualizada', 'success', 'Etiqueta actualizada.');
        })
        .catch((e) => {});
    } catch (error) {}
  }
  async AddEtiqueta(evento) {
  
    try {
      let et:any[] = this.etiquetas;
      
      
      this.etiquetas[0].push(evento.target.value);
      
      var formData: any = new FormData();
      formData.append('x-token', this.user.token);
      formData.append('id', this.respuesta?.id);
      formData.append('nueva', evento.target.value);
      formData.append('junta', this.etiquetas.toString());

      await this.http
        .post(
          `${this.ondriveService.urlOndrive}server/addEtiqueta`,
          formData
        )
        .subscribe(data=>{
          evento.target.value = "";
          this.addSingle('Actualizada', 'success', 'Etiqueta Añadida.');
          this.stateChange = true;
          this.change.emit(et);
          
        })
    } catch (error) {}
  }
  onNavigate() {
    window.open(this.link.toString(), '_blank');
  }
}
