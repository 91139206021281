import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Pipe } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api/';
import { ArchivosService } from 'src/app/services/archivos.service';
import { UserService } from 'src/app/services/user.service';
import { OndriveService } from './../../services/ondrive.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent  {
  public miniaturas:any[] = [];
  public nameUser:String;
  public rolUser:String;
  public id_user:String;
  public etipopulares:any[];
  public total_eti: Number;
  constructor(private http: HttpClient,
    private ondriveService: OndriveService,
    private messageService: MessageService,
    private user: UserService,
    private routes: Router,
    private fb: FormBuilder,
    private archivoService: ArchivosService
    ){
      this.setUserVar();
      this.getEtiquetasPopulares();
      var res = this.archivoService.recientes();
      res.then((data:any[])=>{
        data.forEach((d)=>{
          this.archivoService.miniaturas(d.respuesta.id).then((da:any)=>{
            let format = {
              archivo: da,
              respuesta: d
            }
            this.miniaturas.push(format);
        })
      })
      
      
      })
    }
  async setUserVar() {
    this.nameUser = await localStorage.getItem('usuario_nombre');
    this.rolUser = await localStorage.getItem('usuario_rol');
    this.id_user = await localStorage.getItem('uid');
  }
  
  files     : File[]  = [];
  tagsarray      : string[] = [];
  subiendo  : boolean = false;
  textoetiqueta;
  nuevo     = {

    titulo    : "",
    contenido : ""
  }
  UploadForm: FormGroup =  this.fb.group({
    titulo   : ['',[Validators.required]],
    etiqueta: ['',[Validators.minLength(4)]],
    descripcion: ['']
  })
  addSingle(texto: string,tipo:string,detalle:string) {
    
    this.messageService.add({severity:tipo, summary:texto, detail:detalle});
  }
  addTag(){
    let valor = this.UploadForm.controls["etiqueta"].value;
    if(valor.length <= 0) {this.addSingle("Ups","error","Mínimo 1 letra en una etiqueta.");return};
    this.tagsarray.push(valor);
    this.UploadForm.controls["etiqueta"].reset()
  }
  deleteTag(i: number){
    this.tagsarray.splice(i,1);
  }
  onSelect(event) {
    this.files.push(...event.addedFiles);
    this.files.forEach((archivo: File,index:number)=>{

    })
  }
  getInteger(val){
    return Math.round(val);
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onSubmit(event:Event){
    event.preventDefault();
    let titulo = this.UploadForm.controls["titulo"].value;
    let descripcion = this.UploadForm.controls["descripcion"].value;
    
    if(titulo =="" || descripcion =="" || this.files.length <= 0){
      this.addSingle("Ups","error","Título, descripción e imagen  son obligatorios");
      
    }else{
      this.subiendo = true;
      this.files.forEach(async (archivo:File,index:number)=>{
        if(archivo.name !== ""){
          var formData: any = new FormData();
          formData.append("DriveData", archivo);
          formData.append('x-token',this.user.token)
          formData.append('titulo',titulo)
          formData.append('etiquetas',this.tagsarray)
          formData.append('contenido',descripcion)
          formData.append('uid',this.id_user)
          await this.http.post(`${this.ondriveService.urlOndrive}back/files/upload`,formData).toPromise().then(data=>{
            if(index >= (this.files.length - 1)){
              this.addSingle("¡Correcto!","success","Archivo cargado con éxito.");
              this.clearInput();
              this.subiendo = false;
            }
            
          }).catch(e=>{
            this.routes.navigate(['login']);

            this.addSingle("Error","error","Su sesión ha vencido.");
          });
        }
        
    })
    }

    
  }
  async getEtiquetasPopulares(){
    var formData: any = new FormData();
    formData.append('x-token',this.user.token)
          
          await this.http.post(`${this.ondriveService.urlOndrive}server/getEtiquetasPopulares`,formData).toPromise().then((data:any)=>{
              this.etipopulares = data;
              let sum = this.etipopulares.reduce(function (a, b) { // function(previousValue, currentValue)
                return {
                  total: a.total + b.total, //select age in object array
                };
              });
              this.total_eti = sum;
              
              
              
            
          })
  }

  clearInput(){
              this.UploadForm.controls["titulo"].reset();
              this.UploadForm.controls["descripcion"].reset();
              this.files = [];
              this.tagsarray = [];
  }

}
