import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArchivosService {

  constructor() { }
  
   buscar(text:string){
    return new Promise(async (resolve,reject)=>{
      try {
        let tk = await localStorage.getItem('jwt');
        var myHeaders = new Headers();
          
        
    
        var formdata = new FormData();
        formdata.append("x-token", tk);
        formdata.append("q", text);
        
        fetch(environment.urlback+"server/archivos", {
          method: 'POST',
          headers: myHeaders,
          body: formdata
        }).then((response) => response.json()).then((body:any)=>{
            
            
            if(body.error){ reject(false)}
            resolve(body)
          })
          .catch((error) => {reject(false);console.log(error)});
      } catch (error) {
        reject(error)
      }
     
      })
   }
   miniaturas(text:string){
    return new Promise(async (resolve,reject)=>{
      try {
        let tk = await localStorage.getItem('jwt');
        var myHeaders = new Headers();
          
        
    
        var formdata = new FormData();
        formdata.append("x-token", tk);
        formdata.append("item", text);
        
        fetch(environment.urlback+"server/miniaturas", {
          method: 'POST',
          headers: myHeaders,
          body: formdata
        }).then((response) => response.json()).then((body:any)=>{
            
            
            if(body.error){ reject(false)}
            
            resolve(body)
          })
          .catch((error) => {reject(false);console.log(error)});
      } catch (error) {
        reject(error)
      }
     
      })
   }
   recientes(){
    return new Promise(async (resolve,reject)=>{
      try {
        let tk = await localStorage.getItem('jwt');
        var myHeaders = new Headers();
          
        
    
        var formdata = new FormData();
        formdata.append("x-token", tk);
        
        fetch(environment.urlback+"server/recientes", {
          method: 'POST',
          headers: myHeaders,
          body: formdata
        }).then((response) => response.json()).then((body:any)=>{
            
            
            if(body.error){ reject(false)}
            
            resolve(body)
          })
          .catch((error) => {reject(false);console.log(error)});
      } catch (error) {
        reject(error)
      }
     
      })
   }
   etiquetas(text:string){
    return new Promise(async (resolve,reject)=>{
      try {
        let tk = await localStorage.getItem('jwt');
        
        var myHeaders = new Headers();
          
        
    
        var formdata = new FormData();
        formdata.append("x-token", tk);
        formdata.append("q", text);
        fetch(environment.urlback+"server/etiquetas", {
          method: 'POST',
          headers: myHeaders,
          body: formdata
        }).then((response) => response.json()).then((body:any)=>{
            
            
            if(body.error){ reject(false)}
            
            resolve(body)
          })
          .catch((error) => {reject(false);console.log(error)});
      } catch (error) {
        reject(error)
      }
     
      })
   }
   etiquetasId(text:string){
    return new Promise(async (resolve,reject)=>{
      try {
        let tk = await localStorage.getItem('jwt');
        
        var myHeaders = new Headers();
          
        
    
        var formdata = new FormData();
        formdata.append("x-token", tk);
        formdata.append("q", text);
        fetch(environment.urlback+"server/etiquetas/id", {
          method: 'POST',
          headers: myHeaders,
          body: formdata
        }).then((response) => response.json()).then((body:any)=>{
            
            
            if(body.error){ reject(false)}
            
            resolve(body)
          })
          .catch((error) => {reject(false);console.log(error)});
      } catch (error) {
        reject(error)
      }
     
      })
   }
}
