import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OndriveService {

  constructor() { }
  public urlOndrive = environment.urlback;
}
