import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery/gallery.component';
import { ModalComponent } from './modal/modal.component';
import {DialogModule} from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule } from '@angular/forms';
import { TagModule } from 'primeng/tag';


@NgModule({
  declarations: [
    GalleryComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    ReactiveFormsModule,
    TagModule
  ],
  exports: [
    GalleryComponent,
    ModalComponent
  ]
})
export class WidgetsModule { }
