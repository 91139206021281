import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BuscarComponent } from "./buscar/buscar.component";
import { DashboardComponent } from "./dashboard.component";

const routes: Routes = [
    {
        path: 'subir',
        pathMatch: 'full',
        children:[
            {
                path:'',
                component: DashboardComponent
            },
        ]
    },
    {
        path: 'buscar',
        component: BuscarComponent
    }
]
@NgModule({
    imports:[
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class DashboardRoutingModule{

}
