// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlback : "https://multigan.fedegan.org.co/api/",
  // urlback : "http://localhost:3000/",
  firebase: {
    apiKey: "AIzaSyD_RUwo6cBQc4ihyWzdSADNHoldDjCmOGk",
    authDomain: "fedegan-6f2c8.firebaseapp.com",
    projectId: "fedegan-6f2c8",
    storageBucket: "fedegan-6f2c8.appspot.com",
    messagingSenderId: "243341221410",
    appId: "1:243341221410:web:51445220fd1e23c579747b"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
