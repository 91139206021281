import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginGuard } from './auth/login.guard';
import { DashboardModule } from './auth/dashboard/dashboard.module';
import { LoginComponent } from './auth/dashboard/login/login.component';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from './services/user.service';
import { ArchivosService } from './services/archivos.service';
import { OndriveService } from './services/ondrive.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase)
    
  ],
  providers: [
    UserService,
    ArchivosService,
    OndriveService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
